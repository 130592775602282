import { host } from '@/const'
import { Area, AreaMeta } from '@/pages/areas/types'
import { DocumentEntity, DocumentMeta } from '@/pages/documentation/types'
import { ref } from 'vue'

export interface MessagesWebSocket {
  type: 'update_zone'|'update_document'|'new_document'|'new_document'|string
  data: DocumentEntity|Area
}

export class TableWebSocket {
  webSocket: WebSocket | null = null
  messages = ref<MessagesWebSocket[]>([])

  constructor () {
    const wsToken = `${localStorage?.getItem('auth')}`
    const wsApi = `${host}`.split('/')[2]

    const metaDocument = new DocumentMeta()
    const metaArea = new AreaMeta()

    this.webSocket = new WebSocket(`ws://${wsApi}:8000/ws/?token=${wsToken}`)

    this.webSocket.onmessage = (message: any) => {
      const dataMessage = JSON.parse(message.data)

      this.messages.value.push({
        type: dataMessage.type,
        data: dataMessage.type.includes('_zone')
          ? metaArea.load(dataMessage.data)
          : metaDocument.load(dataMessage.data)
      })
    }
  }
}
import { User } from '@/core/user/types'
import { reactive } from 'vue'
import { INotification, IProject } from './types'
import { MessagesWebSocket } from '@/core/web-socket'

export const store = reactive({
  currentProject: {
    id: '',
    name: '',
    address: ''
  } as IProject,
  notification: [] as INotification[],
  user: new User(),
  messagesWebSocket: [] as MessagesWebSocket[]
})

export const mutations = {
  pushNotification (message: string, error: boolean = false): void {
    store.notification.push({ message, error, checked: false })
    const { length } = store.notification
    setTimeout(() => { this.deleteNotification(length - 1) }, 5000)
  },
  deleteNotification (idx: number): void {
    if (!store.notification[idx] || store.notification[idx].checked) return

    new Promise<void>((resolve): void => {
      store.notification[idx].checked = true
      resolve()
    }).finally(() => {
      const { length } = store.notification
      const countChecked = store.notification.reduce((acc, x) => x.checked ? acc + 1 : acc, 0)
      if (length === countChecked) store.notification = []
    })
  },
  setCurrentProject (item: IProject): void {
    store.currentProject = item
  },

  recordMessagesWS (messages: MessagesWebSocket[]): void {
    store.messagesWebSocket = messages
  }
}

import { createApp, nextTick } from 'vue'
import App from './App.vue'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes = [
  { name: 'projects', path: '/projects', component: () => import('./pages/projects/index.vue'), meta: { title: 'Проекты' } },
  { name: 'project-id', path: '/projects/:id', component: () => import('./pages/projects/project.vue'), meta: { title: 'Проект - ' } },
  { name: 'employee', path: '/employee', component: () => import('./pages/employee/index.vue'), meta: { title: 'Cотрудники' } },
  { name: 'areas', path: '/areas', component: () => import('./pages/areas/index.vue'), meta: { title: 'Зоны' } },
  { name: 'products', path: '/products', component: () => import('./pages/products/index.vue'), meta: { title: 'Товары' } },
  // { name: 'products-in-areas', path: '/products-in-areas', component: () => import('./pages/products-in-areas/'), meta: { title: 'Товары в зонах' } },
  { name: 'terminals', path: '/terminals', component: () => import('./pages/terminals/index.vue'), meta: { title: 'Терминалы' } },
  {
    name: 'documentation',
    path: '/documentation',
    component: () => import('./pages/documentation/index.vue'),
    meta: { title: 'Документы' },
    children: [
      {
        name: 'documentation-id',
        path: ':id',
        component: () => import('./pages/documentation/positon')
      }
    ]
  },
  /* { name: 'roles', path: '/roles', component: () => import('./pages/roles/index.vue'), meta: { title: 'Роли' } },
  { name: 'journal', path: '/journal', component: () => import('./pages/journal/index.vue'), meta: { title: 'Журнал' } }, */
  { name: 'reports', path: '/reports', component: () => import('./pages/reports/index'), meta: { title: 'Отчеты' } },
  { path: '/:pathMatch(.*)*', redirect: '/projects' }

] as RouteRecordRaw[]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.afterEach((to, from) => {
  document.title = to.meta.title as string
})

createApp(App).use(router).mount('#app')

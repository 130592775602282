
import { Vue, prop } from 'vue-class-component'
import axios from '@/axios'
import { DefaultInput, ModalForm, Entity, EntityMeta, FormErrors } from 'apptimizm-ui'
import { host } from '../const'

const endpoint = host + '/api/v1/auth/token/'

class Props {
  onAuth = prop<() => void>({ required: true })
}

class Requisites extends Entity {
  phone = ''
  password = ''
}

class RequisitesMeta extends EntityMeta<Requisites> {
  fields = {
    password: { type: String },
    phone: { type: String }
  }
}

export default class App extends Vue.with(Props) {
  item: Requisites = new Requisites()
  errors: FormErrors = new FormErrors({})
  showPopup: boolean = false

  render () {
    const toggle = () => { this.showPopup = !this.showPopup }
    const setPhone = (value: string) => {
      const x = value.replace(/\D/g, '').slice(0, 11).match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/)

      if (x === null) return

      let result = ''

      if (x[1]) result += `+${x[1]}`
      if (x[2]) result += ` (${x[2]}`
      if (x[3]) result += `) ${x[3]}`
      if (x[4]) result += `-${x[4]}`
      if (x[5]) result += `-${x[5]}`

      this.item.phone = result
      this.$forceUpdate()
    }

    const modalForm = () => {
      return (
        <ModalForm cancel={toggle} confirm={this.restorePassword} header="Восстановить пароль"
          slots={{
            default: () => {
              return (
                <div class="mt-4">
                  <p>Введите ваш логин и мы вышлем на почту ссылку для восстановления пароля</p>
                  <label class="mt-4">
                    <DefaultInput class={this.item.phone.length ? 'placeholder-up' : ''} placeholder="Введите логин" modelValue={this.item.phone} onValueChange={(v: string) => { this.item.phone = v }} errors={this.errors.forField('phone')}/>
                  </label>
                </div>
              )
            },
            buttons: () => <button onClick={this.restorePassword} class="primary-button mt-4">Сохранить</button>
          }}
        />
      )
    }

    return (
      <div class="auth-page">
        <form class="requisites">
          <h1 class="logo">DEMO</h1>
          <label class="mt-4">
            <DefaultInput class={this.item.phone.length ? 'input-placeholder-up' : ''} placeholder="Введите логин" modelValue={this.item.phone} onValueChange={(v: string) => setPhone(v)} errors={this.errors.forField('phone')}/>
          </label>
          <label class="mt-2">
            <DefaultInput class={this.item.password.length ? 'input-placeholder-up' : ''} password={true} placeholder="Пароль" modelValue={this.item.password} onValueChange={(v: string) => { this.item.password = v }} errors={this.errors.forField('password')}/>
          </label>
          <button class="primary-button mt-2" onClick={(e: Event) => { this.save(e) }}>Войти</button>
          <div class="restore-password mt-2" onClick={toggle}>Восстановить пароль</div>
        </form>
        {this.showPopup ? modalForm() : null}
      </div>
    )
  }

  async restorePassword () {
    console.log('submit')
  }

  async authorization (meta: RequisitesMeta) {
    const response = (await axios.post(endpoint, meta.dump(this.item))).data

    if (response?.errors) this.errors = meta.errors(response.errors)

    if (response.access) {
      localStorage.auth = response.access
      this.onAuth()
    }
  }

  async save (e: Event) {
    e.preventDefault()

    try {
      const meta = new RequisitesMeta()
      const license = await axios.post(`${host}/api/v1/user/check-license/`, meta.dump(this.item))

      if (license.status === 200) this.authorization(meta)
    } catch (err) {
      const error = err as any

      switch (error.response.status) {
        case 401:
          alert('Обратитесь к поставщику ПО для доступа к аккаунту')

          break
        case 503:
          alert('Отсутствует связь с сервером проверки лицензии, проверьте подключение к интернету.')

          break
        default:
          break
      }
    }
  }
}
